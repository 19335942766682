@import url('https://fonts.googleapis.com/css2?family=Teko:wght@300..700&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&family=Roboto:wght@400;700&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&family=Open+Sans:wght@400;600&family=Anek+Devanagari:wght@100..800&family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Vina+Sans&display=swap&family=Bebas+Neue&display=swap&family=Nerko+One&display=swap');
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
  @apply bg-retrobg text-retrocream font-body;
}

.starterText {
  @apply text-base font-body text-retrobg bg-retrocream p-4 rounded-lg shadow-md;
}

.icons {
  display: inline;
  height: 25px;
  width: 25px;
}

.url-text{
  @apply text-retropink;
}

.starterTitle{
  @apply justify-center text-2xl font-chat mb-2 text-retrocream;
}
  
.starterTitle2{
  @apply justify-center text-2xl font-chat text-retrocream;
}

h1, h2, h3, h4, h5 {
  @apply font-heading;
}

h6{
  @apply font-teko;
}

.basicLists{
  @apply ml-5;
  list-style-type:disc;
}
  
.navbar, .footer {
  @apply font-heading flex flex-wrap;  
}

.nav-link {
  @apply hover:bg-retropb px-3 py-2 rounded text-center;
}

/* Adjustments for mobile view */
@media (max-width: 1024px) {
  .navbar .container {
    @apply flex-col items-start;
  }

  .navbar ul {
    @apply flex-col w-full;
  }

  .navbar li {
    @apply w-full;
  }

  .navbar .group-hover\\:block {
    display: block;
  }

  .navbar .absolute {
    position: relative;
  }

  .navbar .hidden {
    display: none;
  }
}

/* Adjustments for larger screens */
@media (min-width: 1024px) {
  .navbar .hidden {
    display: flex;
  }

  .navbar .group-hover\\:block {
    display: block;
  }

  .navbar .absolute {
    position: absolute;
  }
}

.mini-section {
  @apply inline-block p-4 m-2 rounded-full cursor-pointer text-left transition-transform duration-200 h-16 max-w-72;
  @apply bg-retrocream text-retropb font-teko;
}

.mini-section:hover {
  @apply bg-gray-200;
  animation: wiggle 0.5s ease infinite;
}

@keyframes wiggle {
  0%, 100% { transform: rotate(0deg); }
  25% { transform: rotate(3deg); }
  50% { transform: rotate(0deg); }
  75% { transform: rotate(-3deg); }
}

@layer components {
  .table1 {
    @apply bg-retrosilver border-retropb w-fit max-w-[100%];
    border-collapse: collapse;
    border: solid 2px;
  }

  .table1 th {
    @apply text-white px-3 py-2;
  }

  .table1 thead {
    @apply bg-retropb border rounded-md border-retropink;
  }

  .table1 td{
    @apply border-retropb border-b border-l pl-2 pr-2;
  }

  .tablelb {
    @apply w-[100%] text-sm font-leaderboard text-center text-retrocream;
  }

  .tablelb thead{
    @apply sticky top-0 m-0 p-0 z-20;
  }
  
  .tablelb th {
    @apply bg-retrobabypink text-white text-center hover:text-retropink;
  }

  .tablelb th:first-child, .tablelb td:first-child{
    @apply text-left w-[20%];
  }

  .tablelb th, .tablelb td {
    @apply px-3 py-2;
    min-width: 20px; /* Set a minimum width for all columns */
    max-width: 200px; /* Set a maximum width for all columns */
    overflow: hidden; /* Hide overflow content */
    text-align: center;
}

  .tablelb img{
    @apply mx-auto inline w-5 h-5;
  }
  
  .tablelb thead {
    @apply bg-retrobabypink text-xs border-x border-retrobabypink;
  }
  
  .tablelb tbody {
    @apply border-retrobabypink border border-2 text-xs bg-retropb;
  }
  
  .tablelb tbody tr {
    @apply border-b border-dashed border-retrobabypink;
  }
  
  .tablelb td {
    @apply px-3 py-2;
    text-align: center;
  }  

  .tablelb2 {
    @apply w-[100%] text-sm font-leaderboard text-center text-retrocream;
  }

  .tablelb2 thead{
    @apply sticky top-0 m-0 p-0 z-20;
  }
  
  .tablelb2 th {
    @apply bg-retrobabypink text-white text-center hover:text-retropink;
  }

  .tablelb2 th:first-child, .tablelb td:first-child{
    @apply text-left w-2;
  }

  .tablelb2 th, .tablelb td {
    @apply px-3 py-2;
    min-width: 60px; /* Set a minimum width for all columns */
    max-width: 200px; /* Set a maximum width for all columns */
    overflow: hidden; /* Hide overflow content */
    text-align: center;
}

  .tablelb2 img{
    @apply mx-auto inline w-5 h-5;
  }
  
  .tablelb2 thead {
    @apply bg-retrobabypink text-xs border-x border-retrobabypink;
  }
  
  .tablelb2 tbody {
    @apply border-retrobabypink border border-2 text-xs bg-retropb;
  }
  
  .tablelb2 tbody tr {
    @apply border-b border-dashed border-retrobabypink;
  }
  
  .tablelb2 td {
    @apply px-3 py-2;
    text-align: center;
  }

  .tablelb3 {
    @apply w-[100%] text-sm font-leaderboard text-center text-retrocream;
  }

  .tablelb3 thead{
    @apply sticky top-0 m-0 p-0 z-20;
  }
  
  .tablelb3 th {
    @apply bg-retrobabypink text-white text-center hover:text-retropink;
  }

  .tablelb3 th:first-child, .tablelb3 td:first-child{
    @apply w-60;
  }

  .tablelb3 th, .tablelb3 td {
    @apply px-3 py-2;
    min-width: 20px; /* Set a minimum width for all columns */
    max-width: 200px; /* Set a maximum width for all columns */
    overflow: hidden; /* Hide overflow content */
    text-align: center;
}

  .tablelb3 img{
    @apply mx-auto inline w-5 h-5;
  }
  
  .tablelb3 thead {
    @apply bg-retrobabypink text-xs border-x border-retrobabypink;
  }
  
  .tablelb3 tbody {
    @apply border-retrobabypink border border-2 text-xs bg-retropb;
  }
  
  .tablelb3 tbody tr {
    @apply border-b border-dashed border-retrobabypink;
  }
  
  .tablelb3 td {
    @apply px-3 py-2;
    text-align: center;
  }
}

@layer base {
  .font-outline-2 {
    -webkit-text-stroke: 0.5px rgb(241, 216, 241);
  }
  .font-outline-4 {
    -webkit-text-stroke: 4px rgb(255, 255, 255);
  }
}

.expanded-section {
  @apply p-4 m-2 rounded-lg shadow bg-retrogray text-retrocream w-full;
  transition: max-height 0.2s ease-out;
  overflow: hidden;
}

@media (max-width: 768px) {
  .mini-section {
    @apply w-full;
  }

  .expanded-section {
    @apply w-full;
  }

  .table1{
    @apply flex-wrap;
  }

}

.prettyDivide{
  border-radius: 3px;
  border-top: 2px solid #E966A0;
  margin: auto;
  width: 30%;  
}

.mapImage{
  display: flex;
  justify-content: center;
}

::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #5b627e;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #374259;
}

.questHeading{
  @apply bg-retropurple text-white py-2 px-4 rounded-xl text-center hover:bg-retrogray transition-colors duration-300 cursor-pointer mr-2;
}

.questHeading1{
  @apply bg-retropink text-white py-2 px-4 rounded-xl text-center hover:bg-retrogray transition-colors duration-300 cursor-pointer mr-2;
}

.top-farms-board-container {
  @apply text-retropink;
  position: relative;
  display: inline-block;
}

.top-farms-board-image {
  @apply bg-retropb border border-solid border-retrogray;
  position: absolute;
  top: 100%;
  left: 0;
  width: 600px;
  display: none; 
  z-index: 10;
}

.top-farms-board-container:hover .top-farms-board-image {
  display: block; /* Show the image on hover */
  transform: scale(3);
}

.gif-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.gif-box {
  position: relative;
  padding: 10px;
  overflow: hidden; /* Clip overflowing content */
  transition: transform 0.3s; /* Smooth transition for scaling */
}

.gif-box img {
  border: 2px solid #000;
  display: block;
  width: 100%;
  height: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.gif-box:hover {
  transform: scale(2.5); /* Apply scale on hover */
  z-index: 10;
}

.gif-box:hover img {  
  @apply border-dashed border-retropb;
  transition: none; /* Disable transition for the image */
}


.avatarInfo img{
  display: block;
  width: 100px;
  height: 100px;
  margin-left: auto;
  margin-right: auto;
}

.animation{
  @apply text-center text-2xl text-retroegg font-body;
}

.myLinks{
  @apply hover:text-retropink text-retropurple;
}

.guild-shards-container {
  max-height: 7rem; /* Adjust based on your needs */
  padding: 0.5rem;  /* Adjust as needed */
  border-radius: 0.5rem; /* Optional rounded corners */
  overflow-y: auto; /* Enables vertical scrolling */
}

.rbc-calendar .rbc-row-bg .rbc-day-bg.rbc-off-range {
  background-color: #757aac; /* Background color for leading days */
  color: #888; /* Text color for leading days */
}

.rbc-header {
  background-color: #333; /* Header background color */
  color: white; /* Header text color */
}

/* Customize the date cell background */
.rbc-day-bg {
  @apply bg-retrobabypink text-white;
}

.rbc-btn{
  @apply text-white;
}

.rbc-calendar {
  @apply font-leaderboard text-sm;
}

.rbc-off-range-bg{
  @apply bg-gray-600 text-white;
}

.rbc-off-range {
  @apply text-white;
}

.rbc-toolbar button{
  @apply text-white;
}

.rbc-toolbar button.rbc-active{
  @apply text-retrogray;
}